<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Which of the following graphs is not a possible plot of concentration vs time for a
        reactant?
      </p>

      <p class="mb-2 pl-6">
        <v-img src="/img/assignments/concentrationVsTimePlotOptions.png" max-width="491px" />
      </p>

      <v-radio-group v-model="inputs.multipleChoiceAnswer" class="mb-0" :disabled="!allowEditing">
        <v-radio v-for="option in options" :key="option.value" class="my-1" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">b) I think this because:</p>

      <p class="mb-n2">
        <s-textarea v-model="inputs.explainedResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'UBC154KineticsPrelabQuestion12',
  components: {
    ChemicalLatex,
    STextarea,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        multipleChoiceAnswer: null,
        explainedResponse: null,
      },
      options: [
        {text: 'Plot A', value: 'Plot A'},
        {text: 'Plot B', value: 'Plot B'},
        {text: 'Plot C', value: 'Plot C'},
        {text: 'Plot D', value: 'Plot D'},
      ],
    };
  },
};
</script>
